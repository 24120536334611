.header-color {
  background: #bdc3c7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer-color {
  background: #bdc3c7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer-color p {
  font-size: 12px;
  color: #fff;
  align-self: center;
  padding-top: '30%';
}

.landing-grid {
  background: #bdc3c7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid-picture {
  background: url('./assets/mountain2.jpg') no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.demo-big-content {
  text-align: center;
}

.avatar-img {
  position: 'absolute';
  justify-content: center;
  height: 300px;
  align-self: center;
}

.avatar-img-edu {
  position: 'absolute';
  justify-content: center;
  height: 250px;
  align-self: center;
  border-width: 25px;
  border-style: solid;
  padding: 10px;
}

.avatar-img-2 {
  height: 250px;
  padding: 20px;
}

.avatar-img-landing {
  height: 350px;
}

.banner-text-white {
  opacity: 0.8;
  position: 'absolute';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.banner-text-white h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.banner-text-white h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.banner-text-white h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.banner-text-white p {
  padding-top: 20;
  margin-top: 20;
}

.banner-text-white ul {
  list-style-position: outside;
  margin-left: 0;
  padding-left: 20px;
  padding-top: 0;
  margin-top: 0;
}

.banner-text {
  /*background-color: black;*/
  opacity: 0.8;
  position: 'absolute';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.banner-text h1 {
  font-size: 65px;
  font-weight: bold;
  color: white;
}

.banner-text h4 {
  color: white;
}

.banner-text h3 {
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 0em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.social-links-footer {
  display: flex;
  width: 90%;
}

.social-links-footer i {
  color: white;
  font-size: 2em;
  padding: 10px;
}

.logo-img {
  padding-top: 0em;
  height: 25px;
  justify-content: 'center';
}

.category-tabs {
  margin-top: 0%;
}

.social-links-volunteer {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
}

.social-links-volunteer i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.list {
  padding-top: 0;
  margin-top: 0;
}

.list ul {
  list-style-position: outside;
  margin-left: 0;
  padding-left: 20px;
  padding-top: 0;
  margin-top: 0;
}